<template>
  <v-container>
    <v-row>
      <v-col>
        Fill in the id of your market along with your login credentials and the excel file with the updated salesareas. Then click "Upload".
        <v-text-field label="market id" v-model="marketId" placeholder="df443e9c-9ac3-4a83-9369-89b71831ad48"/>
        <v-text-field label="username" v-model="username"/>
        <v-text-field label="password" v-model="password" type="password"/>
        <v-file-input label="excel file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" v-model="file"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
            @click="uploadFile"
            :disabled="!(marketId && username && password && file)"
        >
          Upload
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-linear v-if="inProgress" :indeterminate="!progress" :value="progress" />
        <v-alert v-if="success" type="success">Salesareas successfully updated.</v-alert>
        <v-alert v-if="error" type="error">{{error}}</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Axios from "axios";
import ExcelJS from 'exceljs'

const apiClient = Axios.create();

export default {
  name: "UploadSalesareasComponent",
  props: {
    baseUrl: String
  },
  data: () => {
    return {
      marketId: "",
      username: "",
      password: "",
      file: undefined,
      inProgress: false,
      progress: undefined,
      error: undefined,
      success: false
    }
  },
  methods: {
    uploadFile: async function () {
      this.success = false;
      this.error = undefined;
      this.inProgress = true;

      apiClient.defaults.baseURL = this.baseUrl;

      try {
        // open file
        let fileBuffer = await this.file.arrayBuffer();
        let workbook = await new ExcelJS.Workbook().xlsx.load(fileBuffer);
        let sheet = workbook.worksheets[0];

        // ### read file contents
        let members = {};
        function addSalesarea(memberId, country, start, end) {
          if (!members[memberId]) {
            members[memberId] = {
              salesareas: []
            }
          }

          if (!country && !start && !end) {
            members[memberId].salesareas = [];
          } else if (!country && !start) {
            throw new Error("Invalid salesarea!");
          } else {
            members[memberId].salesareas.push({
              country: country,
              start: start,
              end: end ? end : undefined
            });
          }
        }

        for (let r = 2; r <= sheet.rowCount; r++) {
          let row = sheet.getRow(r);

          let memberId = row.getCell(1).text;
          let country = row.getCell(4).text;
          let start = row.getCell(5).text;
          let end = row.getCell(6).text;

          addSalesarea(memberId, country, start, end);
        }

        console.log(JSON.stringify(members))

        // ### log in
        let accessToken = await apiClient.post('user/login', {
          username: this.username,
          password: this.password
        })
            .then(response => response.data.accessToken)
            .catch(reason => {
              console.log(reason.toJSON())
              let status = reason.response.status;
              if (status >= 400 && status < 500) {
                this.error = reason.response.data.message;
              } else {
                console.error(reason.response);
              }
              throw reason;
            });

        // ### update salesareas

        // get latest serial of market
        let serial = await apiClient.get(`markets/${this.marketId}`, {
          headers: {
            Authorization: accessToken
          }
        })
            .then(response => response.headers['x-serial'])
            .catch(reason => {
              if (reason.response.status === 404) {
                this.error = "Market not found!";
              } else {
                console.error(reason.response);
              }
              throw reason;
            });

        console.log(serial);

        // update member
        for (let memberEntries = Object.entries(members), i = 0; i < Object.keys(members).length; i++) {
          let memberId = memberEntries[i][0];
          let salesareas = memberEntries[i][1]['salesareas'];
          console.log(memberId, salesareas)

          serial = await apiClient.patch(`markets/${this.marketId}/members/${memberId}`, {
            salesareas
          }, {
            headers: {
              Authorization: accessToken,
              'x-serial': serial
            }
          })
              .then(response => response.headers['x-serial'])
              .catch(reason => {
                console.error(reason.response);
                throw reason;
              });

          this.progress = (i+1) / Object.keys(members).length * 100;
        }

        this.success = true;
      } catch (reason) {
        console.error(reason)
        if (!this.error) {
          this.error = "Unknown error."
        }
      } finally {
        this.progress = undefined;
        this.inProgress = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-container>
    <v-row>
      <v-col>
        Fill in the id of your market along with your login credentials and click "Download".
        <v-text-field label="market id" v-model="marketId" placeholder="df443e9c-9ac3-4a83-9369-89b71831ad48"/>
        <v-text-field label="username" v-model="username"/>
        <v-text-field label="password" v-model="password" type="password"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
            @click="downloadFile"
            :disabled="!(marketId && username && password)"
        >
          Download
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-linear v-if="inProgress" :indeterminate="!progress" :value="progress" />
        <v-alert v-if="success" type="success">File downloaded successfully.</v-alert>
        <v-alert v-if="error" type="error">{{error}}</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Axios from 'axios';
import ExcelJS from 'exceljs'
import NodeCache from "node-cache";

const apiClient = Axios.create();

export default {
  name: "DownloadSalesareasComponent",
  props: {
    baseUrl: String
  },
  data: () => {
    return {
      marketId: "",
      username: "",
      password: "",
      inProgress: false,
      progress: undefined,
      error: undefined,
      success: false
    }
  },
  methods: {
    downloadFile: async function () {
      this.success = false;
      this.error = undefined;
      this.inProgress = true;

      apiClient.defaults.baseURL = this.baseUrl;

      // ### log in
      try {
        let accessToken = await apiClient.post('user/login', {
          username: this.username,
          password: this.password
        })
            .then(response => response.data.accessToken)
            .catch(reason => {
              console.debug(reason.toJSON())
              let status = reason.response.status;
              if (status >= 400 && status < 500) {
                this.error = reason.response.data.message;
              } else {
                console.error(reason.response);
              }
              throw reason;
            });

        // ### retrieve lead acceptors list
        let membersSearchResult = await apiClient.post(`markets/${this.marketId}/members/search`, {}, {
          headers: {
            Authorization: accessToken,
            Accept: 'application/vnd.leadtributor.markets.acceptors+json'
          }
        })
            .then(response => response.data)
            .catch(reason => {
              console.error(reason.response);
              throw reason;
            });

        // ### generate excel workbook
        let workbook = new ExcelJS.Workbook();
        let sheet = workbook.addWorksheet('list');

        let memberCache = new NodeCache();
        async function getMember(memberUrn) {

          async function getUserProfile(userId) {
            return apiClient.get(`profile/${userId}`, {
              headers: {
                Authorization: accessToken
              }
            })
                .catch(error => {
                  console.error(error);
                  throw error;
                })
                .then(response => response.data);
          }

          if (!memberCache.has(memberUrn)) {
            let userCheck = checkForUserUrn(memberUrn);
            if (userCheck.isUserUrn) {
              memberCache.set(memberUrn, await getUserProfile(userCheck.userId));
            } else {
              throw new Error('memberUrn not recognized!');
            }
          }
          return memberCache.get(memberUrn);
        }

        // header
        sheet.getCell(1,1).value = 'memberId';
        sheet.getCell(1,2).value = 'name';
        sheet.getCell(1,3).value = 'email';
        sheet.getCell(1,4).value = 'country';
        sheet.getCell(1,5).value = 'start';
        sheet.getCell(1,6).value = 'end';

        // salesareas
        let sheetEntry = 2;
        for (let i = 0; i < membersSearchResult['acceptors'].length; i++) {
          let acceptor = membersSearchResult['acceptors'][i];

          function addEntry(memberId, name, email, country, start, end) {
            sheet.getCell(sheetEntry, 1).value = memberId;
            sheet.getCell(sheetEntry, 2).value = name;
            sheet.getCell(sheetEntry, 3).value = email;
            sheet.getCell(sheetEntry, 4).value = country;
            sheet.getCell(sheetEntry, 5).value = start;
            sheet.getCell(sheetEntry, 6).value = end;

            sheetEntry++;
          }

          let member = await getMember(acceptor['membership']['member']);
          console.log(member);

          if (acceptor['salesareas'] && acceptor['salesareas'].length > 0) {
            for (let salesarea of acceptor['salesareas']) {
              addEntry(acceptor['membership']['id'], member['userinfo']['name'], member['userinfo']['email'], salesarea['country'], salesarea['start'], salesarea['end']);
            }
          } else {
            addEntry(acceptor['membership']['id'], member['userinfo']['name'], member['userinfo']['email']);
          }

          // progress indication
          this.progress = (i+1) / membersSearchResult['acceptors'].length * 100;
        }

        // ### download excel file to desktop
        let fileBuffer = await workbook.xlsx.writeBuffer();
        let fileURL = URL.createObjectURL(
            new Blob(
                [fileBuffer],
                {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
            )
        );

        let link = document.createElement('a');
        link.download = "salesareas.xlsx"
        link.href = fileURL;
        link.click();
        link.remove();
        URL.revokeObjectURL(fileURL);

        this.success = true;
      } catch (reason) {
        console.error(reason)
        if (!this.error) {
          this.error = "Unknown error."
        }
      } finally {
        this.progress = undefined;
        this.inProgress = false;
      }
    }
  }
}

function checkForUserUrn(text) {
  const userUrnMatch = text.match(/urn:lt:users:user:(.*)/);
  if (userUrnMatch !== null) {
    return {
      isUserUrn: true,
      userId: userUrnMatch[1]
    };
  } else {
    return {
      isUserUrn: false
    };
  }
}

function checkForGroupUrn(text) {
  const groupUrnMatch = text.match(/urn:lt:groups:group:(.*)/);
  if (groupUrnMatch !== null) {
    return {
      isGroupUrn: true,
      groupId: groupUrnMatch[1]
    };
  } else {
    return {
      isGroupUrn: false
    };
  }
}
</script>

<style scoped>

</style>
<template>
  <v-container fluid>
    <v-stepper
        vertical
        :value="currentStep"
    >

      <v-stepper-step
          step="1"
          :complete="currentStep > 1"
      >
        Download current salesareas list.
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card>
          <DownloadSalesareasComponent :base-url="baseUrl"/>
          <v-card-actions>
            <v-btn
                @click="currentStep = 2"
            >
              Next step
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
          step="2"
          :complete="currentStep > 2"
      >
        Fill in new/updated salesareas in file.
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                Open the file you just downloaded to your computer with Excel and fill in the new/updated salesareas per lead acceptor.<br/>
                <ul>
                  <li>Every row designates a single salesarea per lead acceptor.</li>
                  <li>A salesarea may be a single postal code: fill <b>country</b>, <b>start</b> fields and leave <b>end</b> blank.</li>
                  <li>A salesarea may be a range of postal codes: fill <b>country</b>, <b>start</b> and <b>end</b> fields.</li>
                  <li>Multiple rows to the same <b>memberId</b> sum up to the same lead acceptor.</li>
                  <li>A row with all blank <b>country</b>, <b>start</b> and <b>end</b> fields removes all salesareas from the respective lead acceptor.</li>
                  <li>List only those lead acceptors whose salesareas you want to update! Lead acceptors which are not present in the file will be ignored and leave unchanged.</li>
                  <li><b>name</b> & <b>email</b> cells are ignored.</li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-btn
                @click="currentStep = 3"
            >
              Next step
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
          step="3"
          :complete="currentStep > 3"
      >
        Upload the updated file.
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card>
          <UploadSalesareasComponent :base-url="baseUrl"/>
          <v-card-actions>
            <v-btn
                @click="currentStep = 4"
            >
              Next step
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
          step="4"
          :complete="currentStep === 4"
      >
        Done.
      </v-stepper-step>

    </v-stepper>
  </v-container>
</template>

<script>
import DownloadSalesareasComponent from "@/components/DownloadSalesareasComponent";
import UploadSalesareasComponent from "@/components/UploadSalesareasComponent";
export default {
  name: "UpdateSalesareasView",
  components: {UploadSalesareasComponent, DownloadSalesareasComponent},
  props: {
    baseUrl: String
  },
  data: () => {
    return {
      currentStep: 1
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-app>

    <v-main>
      <v-app-bar color="primary">
        <v-spacer/>
        <v-switch align="center" dark @change="onEnvironmentChanged" v-model="selectedEnvironment" :label="selectedEnvironment ? 'live' : 'demo'"/>
      </v-app-bar>
      <UpdateSalesareasView :base-url="baseUrl"/>
    </v-main>

  </v-app>
</template>

<script>
import UpdateSalesareasView from './views/UpdateSalesareasView';

export default {
  name: 'App',

  components: {
    UpdateSalesareasView,
  },

  data: () => ({
    selectedEnvironment: true,
  }),

  computed: {
    baseUrl: function() {
      return this.selectedEnvironment === true
          ? 'https://api.leadtributor.cloud'
          : 'https://api.demo.leadtributor.cloud';
    }
  },

  methods: {
    onEnvironmentChanged: async () => {

    }
  }
};
</script>
